import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import { IOS_LINK_TO_STORE, ANDROID_LINK_TO_STORE } from '@features/app';
import './style.scss';
import { readCookie } from '@utils';

// CHANGE THIS PLEASE CHANGE CSS TOO !!
const DEFAULT_BANNER_POSITION = 68;

export const useSmartBanner = () => {
  const [mounted, setMounted] = useState<boolean>(false);
  const refTimeout = useRef<NodeJS.Timer>();

  const isDisabled = useMemo(() => {
    if (!mounted) return;

    const userAgent = navigator.userAgent;

    const allowedUserAgentsRegExp = /^(?=.*\b(?:Android|iPhone|iPad)\b).*$/;
    const isAllowed = allowedUserAgentsRegExp.test(userAgent);

    const isExited = readCookie('smartbanner_exited');
    if (isExited) return true;

    return !isAllowed;
  }, [mounted]);

  const adjustNavbar = useCallback(() => {
    if (!mounted) return;
    if (window.scrollY > DEFAULT_BANNER_POSITION) return;
    const navRef = document.getElementById('mainMenu');

    if (!navRef) return;
    navRef.style.marginTop = '68px';
  }, [mounted]);

  const handleAdjustNavbar = useCallback(
    (scrollY: number) => {
      if (!mounted) return;

      const navRef = document.getElementById('mainMenu');

      if (!navRef) return;
      const defaultPosition = DEFAULT_BANNER_POSITION;
      const currentPosition = Math.max(0, defaultPosition - scrollY);

      navRef.style.marginTop = `${currentPosition}px`;
    },
    [mounted]
  );

  useEffect(() => setMounted(true), []);

  useEffect(() => {
    if (!mounted) return;
    if (isDisabled) return;

    window.addEventListener('scroll', () => handleAdjustNavbar(window.scrollY), false);

    return () => {
      window.removeEventListener('scroll', () => handleAdjustNavbar(window.scrollY), false);
    };
  }, [mounted, handleAdjustNavbar, isDisabled]);

  useEffect(() => {
    if (!mounted) return;
    if (isDisabled) return;

    refTimeout.current = setTimeout(() => {
      if (typeof (window as any).smartbanner === 'undefined') return;
      (window as any).smartbanner.publish();
      adjustNavbar();
    }, 1000);

    return () => clearTimeout(refTimeout.current);
  }, [mounted, adjustNavbar, isDisabled]);
};

export const AppBanner: FunctionComponent = () => {
  useSmartBanner();
  return (
    <Helmet>
      {/* APP BANNER */}
      <meta name="smartbanner:custom-design-modifier" content="dh" />

      <meta name="smartbanner:title" content="จองผ่านแอป Drivehub" />
      <meta name="smartbanner:price" content="สะดวก รวดเร็วกว่า!" />

      <meta name="smartbanner:icon-apple" content="/assets/icons/dh-smart-banner-logo.png" />
      <meta name="smartbanner:button-url-apple" content={IOS_LINK_TO_STORE} />

      <meta name="smartbanner:icon-google" content="/assets/icons/dh-smart-banner-logo.png" />
      <meta name="smartbanner:button-url-google" content={ANDROID_LINK_TO_STORE} />

      <meta name="smartbanner:button" content="เปิด" />
      <meta name="smartbanner:enabled-platforms" content="ios,android" />
      {/* <meta name="smartbanner:hide-path" content={{ exclude_path }} /> */}

      <meta name="smartbanner:api" content="true" />
      <script async type="text/javascript" src="/scripts/smartbanner/smartbanner.js" />
    </Helmet>
  );
};

export default { AppBanner };
