// FEATURES
let _IOS_LINK_TO_STORE = '';
let _ANDROID_LINK_TO_STORE = '';

if (process.env.GATSBY_STAGE === 'production') {
  _IOS_LINK_TO_STORE = process.env.GATSBY_IOS_LINK_TO_STORE || 'https://drivehubth.page.link/web';
  _ANDROID_LINK_TO_STORE = process.env.GATSBY_ANDROID_LINK_TO_STORE || 'https://drivehubth.page.link/web';
} else {
  _IOS_LINK_TO_STORE = process.env.GATSBY_IOS_LINK_TO_STORE || 'https://drivehubth.page.link/web';
  _ANDROID_LINK_TO_STORE = process.env.GATSBY_ANDROID_LINK_TO_STORE || 'https://drivehubth.page.link/web';
}

export const IOS_LINK_TO_STORE = _IOS_LINK_TO_STORE;
export const ANDROID_LINK_TO_STORE = _ANDROID_LINK_TO_STORE;
