import { PureComponent, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { WEB_LEGACY_LINK, WEB_PUSH_PUBLIC_KEY } from '../../../../config';
import { setCookie, readCookie, urlBase64ToUint8Array, canUseDOM } from '@utils';
import { MARKETING_API, VOUCHER_API } from '@config';

import { AppBanner } from './AppBanner';
// import PromotionModal from '@shares/retention-popup';

interface SEOProps {
  title: string;
  description: string;
  prefix: string;
  path?: string;
  canonical?: string;
  lang: string;
  keywords: string;
  isHideReturningPopup?: boolean;
  disableAppBanner?: boolean;
}
const schemaOrgWebPage = {
  '@context': 'http://schema.org/',
  '@type': 'Organization',
  address: {
    '@type': 'PostalAddress',
    addressCountry: 'TH',
    addressLocality: 'Bangkok',
    postalCode: '10110',
    streetAddress: '193-195 Ratchadaphisek Rd, Khwaeng Khlong Toei, Khet Khlong Toei, Krung Thep Maha Nakhon 10110',
    email: 'support@drivehub.co',
    telephone: '+66 2 038 5222',
    alternateName: 'Drivehub',
    description:
      'จะต้องเสียเวลาในการค้นหารถเช่าอยู่ทำไม Drivehub ผู้ให้บริการค้นหาบริษัทเช่ารถรายวัน ราคาถูก มีรถหลากแบบทั้ง รถตู้ รถ SUV รถ Eco ไว้ในที่เดียว',
    name: 'Drivehub'
  },
  aggregateRating: {
    '@type': 'AggregateRating',
    reviewCount: '150',
    bestRating: '5',
    ratingValue: '5',
    worstRating: '1'
  },
  email: 'support@drivehub.co',
  foundingDate: '27 September 2018',
  legalName: 'Drivehub',
  telephone: '+66 2 038 5222',
  alternateName: 'Drivehub',
  description:
    'จะต้องเสียเวลาในการค้นหารถเช่าอยู่ทำไม Drivehub ผู้ให้บริการค้นหาบริษัทเช่ารถรายวัน ราคาถูก มีรถหลากแบบทั้ง รถตู้ รถ SUV รถ Eco ไว้ในที่เดียว',
  mainEntityOfPage: 'www.drivehub.com',
  name: 'Drivehub'
};

interface UserInitAPIResponse {
  data: {
    action: string;
  };
}

class SEO extends PureComponent<SEOProps> {
  static defaultProps = {
    title: '',
    description: '',
    prefix: 'th',
    path: '',
    canonical: '',
    lang: 'th',
    keywords: '',
    disableAppBanner: false
  };

  _intervalID = 0;

  componentDidMount() {
    this._intervalID = window.setInterval(() => {
      const duid = readCookie('__duid');
      if (duid !== null) this.userInit(duid);
    }, 1000);
    if (!window.Notification) return;
    this.askPermissionNotification().then((permission) => {
      if (!permission || permission === 'default') return;
      this.checkUserSubscription();
    });
  }

  checkUserSubscription = () => {
    if (!window.navigator.serviceWorker) return;
    window.navigator.serviceWorker.ready.then((reg) => {
      reg &&
        reg.pushManager &&
        reg.pushManager.getSubscription().then((sub) => {
          if (sub === null) {
            this.getUserSubscription(reg);
          } else {
            this.sendUpdateUserSubscription(sub);
          }
        });
    });
  };

  askPermissionNotification = async () => {
    const currentPermission = Notification.permission;
    if (currentPermission === 'denied') return false;
    if (currentPermission === 'granted') return true;

    return await Notification.requestPermission((result) => {
      if (result === 'granted') return true;
      return false;
    });
  };

  getUserSubscription = (reg: ServiceWorkerRegistration) => {
    const vapidPublicKey = WEB_PUSH_PUBLIC_KEY;
    const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
    const subscribeOptions = {
      userVisibleOnly: true,
      applicationServerKey: convertedVapidKey
    };
    reg.pushManager
      .subscribe(subscribeOptions)
      .then((result) => {
        const userSubscription = JSON.stringify(result);
        this.sendUserSubscription(userSubscription);
      })
      .catch((e) => {
        console.log(`Can't subscribe: ${e}`);
      });
  };

  sendUserSubscription = async (userSubscription: any) => {
    const duid = readCookie('__duid');
    if (!duid) return;
    const userSubscriptionJSON = JSON.parse(userSubscription);
    try {
      await axios.post(`${MARKETING_API}/subscribers`, {
        duid: duid,
        subscription: userSubscriptionJSON
      });
      setCookie('current_subscription', userSubscription, 30, '/');
    } catch (error: any) {
      console.log('sendUserSubscription', { ...error });
    }
  };

  sendUpdateUserSubscription = async (sub: PushSubscription) => {
    const userSubscription = JSON.stringify(sub);
    const userSubscriptionJSON = JSON.parse(userSubscription);
    const duid = readCookie('__duid');
    const currentSubscription = readCookie('current_subscription');
    if (userSubscription === currentSubscription) return;

    try {
      await axios.put(`${MARKETING_API}/subscribers/${duid}`, {
        subscription: userSubscriptionJSON
      });

      setCookie('current_subscription', userSubscription, 30, '/');
    } catch (error: any) {
      console.error('sendUpdateUserSubscription', error);
    }
  };

  userInit = (duid: string) => {
    clearInterval(this._intervalID);
    const currentDUID = readCookie('current_duid');
    if (currentDUID === duid) return;
    return axios
      .post(`${VOUCHER_API}/voucher/init`, {
        duid: duid
      })
      .then(async ({ data }: UserInitAPIResponse) => {
        const actionType = data.action;
        const isUserHaveFirstVisitCookie = readCookie('_FV');
        if (actionType === 'found' && isUserHaveFirstVisitCookie) return;
        const moment = await (await import('moment')).default;
        const datetimeEncoded = window.btoa(moment().format('YYYY-MM-DD HH:mm'));
        setCookie('current_duid', duid, 30, '/');
        setCookie('_FV', datetimeEncoded, 365, '/');
      });
  };

  getDevice = () => {
    if (!canUseDOM()) return 'undefined';
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return 'mobile';
    }
    return 'desktop';
  };
  getConsumerID = () => {
    const consumerID = readCookie('_consumerID');
    return consumerID != null ? consumerID : `nologin_${this.getDevice()}`;
  };
  getTitle = () => {
    if (!canUseDOM()) return 'undefined';
    return document.title;
  };
  getPath = () => {
    if (!canUseDOM()) return 'undefined';
    return document.location.pathname + document.location.search;
  };

  render() {
    const { title, lang, description, keywords, prefix, path, canonical, disableAppBanner } = this.props;

    return (
      <Fragment>
        {!disableAppBanner && <AppBanner />}
        <Helmet
          htmlAttributes={{
            lang
          }}
          title={title}
          meta={[
            {
              property: 'og:title',
              content: title
            },
            {
              property: 'og:type',
              content: 'article'
            },
            {
              property: 'og:url',
              content: `${WEB_LEGACY_LINK}/${prefix}/${path || ''}`
            },
            {
              property: 'og:image',
              content: 'https://d1p3p3st0uh13e.cloudfront.net/favicon/drivehub.jpg'
            },
            {
              property: 'og:description',
              content: description
            },
            {
              property: 'og:sitename',
              content: 'Drivehub'
            },
            {
              name: 'twitter:card',
              content:
                'รวมบริษัทเช่ารถมืออาชีพชั้นนำทั่วประเทศไว้ในที่เดียว ค้นหารถเช่า เช็ครถว่าง เปรียบเทียบราคารถเช่า จองรถเช่าราคาถูก แบบเรียลไทม์!'
            },
            {
              name: 'twitter:creator',
              content: '@drivehubTH'
            },
            {
              name: 'twitter:title',
              content: title
            },
            {
              name: 'twitter:site',
              content: '@drivehubTH'
            },
            {
              name: 'twitter:description',
              content: description
            },
            {
              name: 'twitter:image',
              content: 'https://d1p3p3st0uh13e.cloudfront.net/favicon/drivehub_tw.jpg'
            },
            {
              property: 'fb:admins',
              content: '320705601614112'
            },
            {
              name: 'keywords',
              content: keywords
            },
            {
              name: 'description',
              content: description
            }
          ]}
        >
          {/* <!-- Twitter universal website tag code --> */}
          {/* {process.env.GATSBY_STAGE === 'production' ? (
            <script defer>
              {`
              !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
              },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
              a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
              twq('init','o1sto');
              twq('track','PageView');
            `}
            </script>
          ) : (
            ''
          )} */}
          {/* <!-- End Twitter universal website tag code --> */}

          {/* START LINE SCRIPT */}
          {/* {process.env.GATSBY_STAGE === 'production' ? (
            <script defer>
              {`
                (function(g,d,o){
                  g._ltq=g._ltq||[];g._lt=g._lt||function(){g._ltq.push(arguments)};
                  var h=location.protocol==='https:'?'https://d.line-scdn.net':'http://d.line-cdn.net';
                  var s=d.createElement('script');s.async=1;
                  s.src=o||h+'/n/line_tag/public/release/v1/lt.js';
                  var t=d.getElementsByTagName('script')[0];t.parentNode.insertBefore(s,t);
                    })(window, document);
                _lt('init', {
                  customerType: 'lap',
                  tagId: 'cf03afd4-e4b4-47e9-bb79-782e40943539'
                });
                _lt('send', 'pv', ['cf03afd4-e4b4-47e9-bb79-782e40943539']);
              `}
            </script>
          ) : (
            ''
          )} */}
          {/* END LINE SCRIPT */}

          {/* START LINE SCRIPT */}
          {/* {process.env.GATSBY_STAGE === 'production' ? (
            <noscript>
              {`
                <img height="1" width="1" style="display:none"
                src="https://tr.line.me/tag.gif?c_t=lap&t_id=cf03afd4-e4b4-47e9-bb79-782e40943539&e=pv&noscript=1" />
              `}
            </noscript>
          ) : (
            ''
          )} */}
          {/* END LINE SCRIPT */}

          <script async type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>

          <link rel="canonical" href={`${canonical ? canonical : WEB_LEGACY_LINK}`} />
          <link
            rel="apple-touch-icon"
            sizes="57x57"
            href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-57x57.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="60x60"
            href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-60x60.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="72x72"
            href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-72x72.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="76x76"
            href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-76x76.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="114x114"
            href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-114x114.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="120x120"
            href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-120x120.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="144x144"
            href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-144x144.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="152x152"
            href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-152x152.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-180x180.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="192x192"
            href="https://d1p3p3st0uh13e.cloudfront.net/favicon/android-icon-192x192.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="https://d1p3p3st0uh13e.cloudfront.net/favicon/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="96x96"
            href="https://d1p3p3st0uh13e.cloudfront.net/favicon/favicon-96x96.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="https://d1p3p3st0uh13e.cloudfront.net/favicon/favicon-16x16.png"
          />
          <script defer type="text/javascript" src="/scripts/niffler.min.js?v=0.0.4" />
          <script defer type="text/javascript" src="/scripts/tracking.min.js?v=0.0.7" />
          <script defer type="text/javascript" src="/scripts/modernizr-custom.js" />

          <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js"></script>
          <script>
            {`window.fbAsyncInit = function() {
                FB.init({
                  appId            : '320705601614112',
                  autoLogAppEvents : true,
                  xfbml            : true,
                  version          : 'v7.0'
                });
              };`}
          </script>
        </Helmet>
        {/* {!isHideReturningPopup && <PromotionModal prefix={prefix} />} */}
      </Fragment>
    );
  }
}

export default SEO;
